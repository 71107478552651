import {localStorage, sessionStorage} from "./storage";
// import io from "socket.io-client";
import { ElLoading, ElMessage  } from "element-plus";
import router from '../router/index'

let host = 'https://api.maogoufan.com';



// fetch
// 通用参数
const commonParams = {
  // realm: 'texas',
  // process: 'web',
}

const request = async (path, options) => {
  let userInfo = localStorage.get('loginUserInfo')
  let { method = 'post', mode = 'cors', credentials = 'include', headers, data = {}, responseType = 'json' } = options
  let paramString = ''
  let fullyUrl = `${host}${path}`
  const padding = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': `Bearer ${userInfo?.token}`,
      ...headers
    },
    method,
    mode,
  }
  const { uid, token } = localStorage.get('loginUserInfo')
  if (uid && token) {
    commonParams.uid = uid
    commonParams.token = token
  }
  data = {
    ...commonParams,
    ...data
  }
  // get请求传参
  if (data && method === 'get') {
    for (const prop in data) {
      const item = `&${prop}=${data[prop]}`
      paramString += item
    }
    paramString = paramString.replace(/^&/, '?')
    fullyUrl += paramString
  }

  // post请求传参
  if (method === 'post') {
    if (headers && headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
      padding.body = new URLSearchParams(data)
    } else {
      padding.body = JSON.stringify(data)
    }
    // 文件上传
    if (options.upload) {
      delete padding.headers['Content-Type']
      padding.body = options.data
    }
  }



  try {
    return await new Promise((resolve, reject) => {
      // let loadingInstance = ElLoading.service({
      //   lock: true,
      //   text: '加载中',
      //   background: 'rgba(0, 0, 0, 0.7)',
      //   fullscreen: true
      // })
      fetch(fullyUrl, padding).then(res => {
        res.headers.get('Access-Check-Key') && sessionStorage.set('Access-Check-Key', res.headers.get('Access-Check-Key'));
        if (responseType === 'blob') {
          return res.blob();
        }
        // loadingInstance.close()
        return res.json();
      }).then((res_1) => {
        // loadingInstance.close()
        if (res_1.code === -100) {
          router.replace({
            name: 'login'
          });
          reject(res_1);

          return;
        }
        resolve(res_1);
      }).catch(error => {
        reject(error);
      });
    });
  } catch (error_1) {
    console.log('error', error_1);
  }
}

export default {
  host,
  get(path, options) {
    return request(path, {
      method: 'get',
      ...options
    })
  },
  post(path, options) {
    return request(path, {
      ...options
    })
  },
  upload(path, options) {
    return request(path, {
      upload: true,
      ...options
    })
  },
}