import { defineStore } from 'pinia'
import {localStorage, sessionStorage} from '@/utils/storage'

// 基本数据管理store
export const useBaseStore = defineStore('base', { 
  state() {
    return {
      tabRouterList: sessionStorage.get('tabRouterList') || [], // 已打开的子系统tabs路由
    }
  },
  actions: {
    // 更新已打开路由列表
    updateTabRouterList(routerItem) {
      if (this.tabRouterList.findIndex(item => item.routeName === routerItem.routeName) > -1) {
        return false
      }
      this.tabRouterList.push(routerItem)
      sessionStorage.set('tabRouterList', this.tabRouterList)
    },
    // 重置/设置已打开路由列表
    setTabRouterList(list) {
      this.tabRouterList = list
      sessionStorage.set('tabRouterList', list)
    },
    // 清空已打开路由列表项目
    clearTabRouterList() {
      this.tabRouterList = []
      sessionStorage.set('tabRouterList', [])
    },
  },
})

// 用户管理store
export const useUserStore = defineStore('user', { 
  state() {
    return {
      loginUserInfo: localStorage.get('loginUserInfo') || null
    }
  },
  actions: {
    // 设置已登录用户信息
    setLoginUserInfo(data) {
      this.loginUserInfo = data
      localStorage.set('loginUserInfo', data)
    },
    // 清除已登录用户信息
    clearLoginUserInfo() {
      this.loginUserInfo = null
      localStorage.remove('loginUserInfo')
    },
  },
})

