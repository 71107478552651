import components from './modules/components'
import adminPages from './modules/adminPages'

const routes = [
    {path: '/', redirect: '/index'},
    {path: '/login', name: 'login', component: () => import('@/views/login/Login')},
    {path: '/:catchAll(.*)', name: '404',  component: () => import('@/views/404/404')},
    {
      // index: 每个子系统公用主页面（包含左侧菜单栏，右侧用户头部，右侧内容区....，子路由根据各子系统菜单数据配置展示，所以无固定默认路由展示）
      path: '/index', 
      name: 'index',
      component: () => import('@/views/index/Index'),
      children: [
        // 组件demo
        ...components,
        // 系统子路由
        ...adminPages,
        // 其他模块子路由

      ]
    },
  ] 
  
export default routes