// 组件展示路由
export default [
  {
    path: '/index/component/searchBar', 
    name: 'searchBar',
    component: () => import('@/views/component/SearchBar'),
    meta: {
      label: '数据搜索栏',
    },
  },
  {
    path: '/index/component/tablePlus', 
    name: 'tablePlus',
    component: () => import('@/views/component/TablePlus'),
    meta: {
      label: '数据表格',
    },
  },

]