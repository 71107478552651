<template>
  <el-table 
    ref="tablePlusRef"
    :data="tableData"
    border
    class="table-plus" 
    @select="handleSelect" 
    @select-all="handleSelectAll" 
    @selection-change="handleSelectionChange" 
    @row-click="handleRowClick"
    @expand-change="handleExpandChange"
    header-cell-class-name="table-header"
  >
    <template v-for="(column, i) in tableColumns" :key="i">
      <el-table-column
        v-if="!column.hasScope"
        :type="column.type || ''"
        :selectable="isSelectable"
        :fixed="column.fixed || false"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :min-width="column.minWidth"
        :align="column.align || 'center'"
      >
      </el-table-column>
      <el-table-column
        v-else
        :type="column.type || ''"
        :selectable="isSelectable"
        :fixed="column.fixed || false"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :min-width="column.minWidth"
        :align="column.align || 'center'"
      >
        <template #default="scope">
          <slot :name="column.slotName" :scopeData="scope" :scopeRow="scope.row"></slot>
        </template>
      </el-table-column>
    </template>
    
  </el-table>
</template>

<script setup>
  import { getCurrentInstance, ref } from 'vue'
  const emits = defineEmits(['handleRowClick', 'handleExpandChange', 'handleSelect', 'selectionChange'])
  const {proxy} = getCurrentInstance()

  const props = defineProps({
    tableColumns: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    // 是否可多选
    isMultiple: {
      type: Boolean,
      default: true
    },
    // rowKey: {
    //   type: [Number, String],
    //   default: ''
    // },
    // checkbox是否可以选择（默认可选）
    isSelectable: {
      type: Function,
      default: () => {
        return true
      }
    }
  })

  const tablePlusRef = ref(null)

  const handleRowClick = (row, column, event) => {
    emits('handleRowClick', row)
  }
  const handleExpandChange = (row, expandedRows) => {
    emits('handleExpandChange', row)
  }
  // 当用户手动勾选数据行的 Checkbox 时触发的事件
  const handleSelect = (selection, row) => {
    emits('handleSelect', selection)
  }
  // 当用户手动勾选全选 Checkbox 时触发的事件
  const handleSelectAll = (selection) => {
    // 如果是单选，要使全选功能失效
    if (!props.isMultiple) {
      tablePlusRef.clearSelection()
      proxy.$message.error('只可单选')
    }
  }
  // 当选择项发生变化时会触发该事件
  const handleSelectionChange = (selections) => {
    // 如果是单选才执行
    if (!props.isMultiple) {
      if (selections.length > 1) {
        // 多选>1 先清除所有，再将最后一个选中
        tablePlusRef.clearSelection()
        // 删除并返回数组的最后一个元素即最后选中的
        let lastSelection = selections.pop()
        tablePlusRef.toggleRowSelection(lastSelection, true)
      } else {
      }
    }

    emits('selectionChange', selections)
  }
</script>

<style lang="less" scoped>
  .table-plus{
    min-width: 100%;
  }
  :deep(.table-header) {
    background-color: #f5f7fa !important;
  }
</style>