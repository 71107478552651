import http from '@/utils/fetch.js'



export default {
  /*
   *@param {json} options: {} || {data: json}
   */
  /* 请务必注释每个接口所属名称 */

  
  
  // host
  host: http.host,

  // 登录
  login: (options = {}) => http.post('/system/auth/account-login', options),
  // 退出登录
  logout: (options = {}) => http.post('/system/auth/logout', options),
  // 新增分类
  createCategory: (options = {}) => http.post('/system/category/add', options),
  // 编辑分类
  editCategory: (options = {}) => http.post('/system/category/edit', options),
  // 删除分类
  deleteCategory: (options = {}) => http.post('/system/category/delete', options),
  // 分类列表
  getCategoryList: (options = {}) => http.get('/system/category/list', options),
  // 分类详情
  getCategoryDetail: (options = {}) => http.get('/system/category/detail', options),
  // 新增书皮
  createBookCover: (options = {}) => http.post('/system/book-cover/add', options),
  // 书皮列表
  getBookCoverList: (options = {}) => http.get('/system/book-cover/list', options),
  // 书皮详情
  getBookCoverDetail: (options = {}) => http.get('/system/book-cover/detail', options),
  // 编辑书皮
  editBookCover: (options = {}) => http.post('/system/book-cover/edit', options),
  // 删除书皮
  deleteBookCover: (options = {}) => http.post('/system/book-cover/delete', options),
  // 关系图列表
  getRelationGraphList: (options = {}) => http.get('/system/relation-graph/list', options),
  // 新增关系图
  createRelationGraph: (options = {}) => http.post('/system/relation-graph/add', options),
  // 关系图详情
  getRelationGraphDetail: (options = {}) => http.get('/system/relation-graph/detail', options),
  // 编辑图列表
  editRelationGraph: (options = {}) => http.post('/system/relation-graph/edit', options),
  // 删除关系图
  deleteRelationGraph: (options = {}) => http.post('/system/relation-graph/delete', options),
  // 意见反馈列表
  getFeedbackList: (options = {}) => http.get('/system/feedback/list', options),
  // 打赏列表
  getRewardList: (options = {}) => http.get('/system/reward-record/list', options),
  // 文件上传
  uploadFile: (options = {}) => http.post('/system/common/local-file-upload', options),
}