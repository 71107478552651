// 管理系统子路由
export default [
  {
    path: '/index/classification-management', 
    name: 'ClassificationManagement',
    component: () => import('@/views/index/ClassificationManagement'),
    meta: {

    },
  },
  {
    path: '/index/book-management', 
    name: 'BookManagement',
    component: () => import('@/views/index/BookManagement'),
    meta: {

    },
  },
  {
    path: '/index/diagram-management', 
    name: 'DiagramManagement',
    component: () => import('@/views/index/DiagramManagement'),
    meta: {

    },
  },
  {
    path: '/index/mapping-management', 
    name: 'MappingManagement',
    component: () => import('@/views/index/MappingManagement'),
    meta: {

    },
  },
  {
    path: '/index/feedback', 
    name: 'Feedback',
    component: () => import('@/views/index/Feedback'),
    meta: {

    },
  },
  {
    path: '/index/tipping-history', 
    name: 'TippingHistory',
    component: () => import('@/views/index/TippingHistory'),
    meta: {

    },
  },
]