<template>
  <RouterView />
</template>

<script setup>

import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useBaseStore } from '@/store/index'
import { getCurrentInstance } from 'vue'
const { proxy } = getCurrentInstance()

const baseStore = useBaseStore()

const { tabRouterList } = storeToRefs(baseStore)
const { clearTabRouterList } = baseStore


watch(proxy.$router.currentRoute, newRoute => {
  // 当跳转到登录页清空系统的tab路由缓存
  if (newRoute.name === 'login') {
    clearTabRouterList()
  }
})


</script>

<style>
:root {
  --el-menu-item-height: 50px;
  --el-border-radius: 2.5px;
  --el-border-radius-base: 2.5px;
  --el-left-menu-width: 266px;
}

body {
  line-height: 1.2;
  font-size: 14px;
  background: #f6f8f9;
  height: 100vh;
  overflow: hidden;
}

ul,
ol {
  list-style: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  overflow-y: hidden;
}

.main-body {
  .main-body-view {
    padding: 20px;
  }

  .main-body-view-wrapper {
    min-height: calc(100vh - 150px);
    background-color: #fff;
    padding: 20px;
  }
}

/* 导图样式 */
jmnode {
  border: none;
  box-shadow: none;
  background-color: #1abc9c;
  color: #fff;
  cursor: pointer;
}

jmnode:hover {
  background-color: #1abc9c;
  box-shadow: none;
  color: #fff;
}

/* 鼠标悬停的节点样式 */
jmnode.selected {
  background-color: #67C23A;
  color: #fff;
  border: none;
  box-shadow: none;
}

/* 选中的节点样式 */
</style>
