import { createApp } from 'vue'
import router from './router'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import '@/assets/css/iconfont.css'
import * as CommonComponentsVue from './components/common'
import 'dayjs/locale/zh-cn' // 日期和时间本地化
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import { createPinia } from 'pinia'
import App from './App.vue'

const $http = require('./api').default

const app = createApp(App)
app.use(router)
app.use(ElementPlus, {locale: zhCn})
app.use(createPinia())
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
for (const [key, component] of Object.entries(CommonComponentsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$http = $http
app.mount('#app')


// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const debounce = (fn, delay) => {
	let timer = null;
	return function () {
		let context = this;
		let args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
}