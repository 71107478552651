<template>
  <div class="search-bar">
    <el-form inline class="search-form" :model="form" label-position="top">
      <el-form-item :class="[`el-form-item_${optionItem.type}`]" :label="optionItem.label" v-for="optionItem in options" :key="optionItem.prop">

        <el-input 
          v-if="optionItem.type === 'input'" 
          :maxlength="optionItem.maxlength || 32" 
          clearable 
          :placeholder="optionItem.placeholder || `请输入${optionItem.label}`" 
          v-model="form[optionItem.prop]" 
        />


        <el-select 
          v-if="optionItem.type === 'select'"
          :placeholder="optionItem.placeholder || `请选择${optionItem.label}`" 
          v-model="form[optionItem.prop]" 
        >
          <el-option
            v-for="item in optionItem.selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>


        <el-cascader
          v-if="optionItem.type === 'cascader'"
          v-model="form[optionItem.prop]"
          :props="optionItem.props || {label: 'label', value: 'value'}"
          :options="optionItem.cascaderOptions"
          :placeholder="optionItem.placeholder || `请选择${optionItem.label}`" 
          @change="cascaderChange"
        />


        <el-date-picker
          v-if="optionItem.type === 'datePicker'"
          v-model="form[optionItem.prop]"
          type="date"
          :placeholder="optionItem.placeholder || `请选择${optionItem.label}`"
          format="YYYY/MM/DD"
          value-format="YYYY/MM/DD"
        />

        <el-date-picker
          v-if="optionItem.type === 'datePickerRange'"
          v-model="form[optionItem.prop]"
          type="daterange"
          :start-placeholder="optionItem.startPlaceholder || '开始日期'"
          :end-placeholder="optionItem.endPlaceholder || '结束日期'"
          format="YYYY/MM/DD"
          value-format="YYYY/MM/DD"
        />


        <el-date-picker
          v-if="optionItem.type === 'dateTimePicker'"
          v-model="form[optionItem.prop]"
          type="datetime"
          :placeholder="optionItem.placeholder || `请选择${optionItem.label}`"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY/MM/DD HH:mm:ss"
        />

        <el-date-picker
          v-if="optionItem.type === 'dateTimePickerRange'"
          v-model="form[optionItem.prop]"
          type="datetimerange"
          :start-placeholder="optionItem.startPlaceholder || '开始日期时间'"
          :end-placeholder="optionItem.endPlaceholder || '结束日期时间'"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY/MM/DD HH:mm:ss"
        />


        <el-time-picker
          v-if="optionItem.type === 'timePicker'"
          v-model="form[optionItem.prop]"
          :placeholder="optionItem.placeholder || `请选择${optionItem.label}`"
          format="HH:mm:ss"
        />


        <el-time-picker
          v-if="optionItem.type === 'timePickerRange'"
          v-model="form[optionItem.prop]"
          is-range
          range-separator="-"
          :start-placeholder="optionItem.startPlaceholder || '开始时间'"
          :end-placeholder="optionItem.endPlaceholder || '结束时间'"
        />


        <el-time-select
          v-if="optionItem.type === 'timeSelect'"
          v-model="form[optionItem.prop]"
          :start="optionItem.timeStart || '00:00'"
          :step="optionItem.timeStep || '00:20'"
          :end="optionItem.timeEnd || '23:00'"
          :placeholder="optionItem.placeholder || `请选择${optionItem.label}`"
        />


      </el-form-item>
      <el-form-item class="btn-group">
        <el-button type="primary" :icon="Search" @click="onSearch">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
  import { Search } from '@element-plus/icons-vue'
  import { reactive } from 'vue'

  const props = defineProps({
    // 尺寸
    size: {
      type: String,
      default: 'default', // 'large' | 'default' | 'small'
    },
    // 表单类型配置列表
    options: {
      require: true,
      type: Array,
      default: [
        // {
        //     label: '表单名称', // 表单名
        //     prop: 'name', // 表单字段
        //     type: 'input/select/datePicker/dateTimePicker/timePicker/timeSelect/cascader/....', // 表单类型
        //     value: '', // 初始表单字段值
        //     placeholder: '', // 占位内容
        //     maxlength: , // 输入最大长度
        //     selectOptions: [{ // select类型表单选项
        //       value: '', // 字段值
        //       label: '' // 字段名
        //     },
        //   ]
        // }
      ],
    },
  })

  const emits = defineEmits(['onSearch', 'onReset', ])

  const form = reactive({})




  // 初始化
  const init = () => {
    props.options.forEach(item => {
      form[item.prop] = item.value
    })
  }
  init()

  // 级联选择器
  const cascaderChange = (value) => {
    console.log('cascaderChange', value)
  }

  // 查询按钮点击
  const onSearch = () => {
    const result = JSON.parse(JSON.stringify(form))
    // 过滤字段值为空
    for (let prop in result) {
      const option = props.options.find(item => item.prop === prop)
      if (typeof result[prop] === 'string') {
        if (!(result[prop].trim())) {
          delete result[prop]
        } else {
          result[prop] = result[prop].trim()
          // 转时间戳
          if (option && 'datePicker/dateTimePicker/timePicker/'.indexOf(option.type) > -1) {
            result[prop] = new Date(result[prop]).getTime()
          }
        }
      }
      if (Array.isArray(result[prop])) {
        if (option && ['datePickerRange', 'dateTimePickerRange', 'timePickerRange'].indexOf(option.type) > -1) {
          if (!result[prop].length) {
            delete result[prop]
          } else {
            // 转时间戳
            result[prop] = [new Date(result[prop][0]).getTime(), new Date(result[prop][1]).getTime()]
          }
        }
        if (option && ['cascader'].indexOf(option.type) > -1) {
          if (!result[prop].length) {
            delete result[prop]
          }
        }
      }
    }
    emits('onSearch', result)
  }
  // 重置按钮点击
  const onReset = () => {
    init()
    emits('onReset')
  }
</script>


<style scoped lang="less">
  .search-bar {
    background-color: #fff;
  }
  .search-form {
    :deep(.el-form-item) {
      width: 220px;
      margin-right: 15px;
      .el-select,.el-input,.el-cascader {
        width: 100%;
      }
    }
    :deep(.el-form-item_dateTimePickerRange) {
      width: 400px;
    }
    :deep(.el-form-item__label) {
      margin-bottom: 5px !important;
    }
  }
  .btn-group {
    width: auto !important;
    display: flex !important;
    align-items: flex-end;
  }
</style>
